import React from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';

import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Upload,
} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

import { isInProduction } from "../utils/isInProduction";

import logo from "../images/logo.svg";
import appIcon from "../images/app-icon.png";

import "antd/dist/antd.css";
import styles from './ItemDetails.module.scss';

class ItemDetails extends React.Component {
  static propTypes = {
    match: PropTypes.object
  }

  state = {
    isDamageReportModalOpen: false,
    isLoading: true,
    data: {}
  }

  componentDidMount() {
    this.damageReportForm = React.createRef();

    this.getItem(this.props.match.params.id);
  }

  getAPIURL = () => (isInProduction ? "https://api.inventar.convela.de" : "http://192.168.1.60:8082") + "/v1"

  getItem = (id) => {
    this.setState({ isLoading: true });
    fetch(this.getAPIURL() + '/items/' + id)
      .then(response => response.json())
      .then(data => this.setState({ item: data, isLoading: false }))
      .catch(error => console.log(error))
  }

  onFormChange = (field, all) => this.setState({ data: all, images: all.images });

  normFile = e => Array.isArray(e) ? e : (e && e.fileList);

  render() {
    const { item, isLoading } = this.state;

    if (!item && isLoading) {
      return <></>;
    }
    if ((!item && !isLoading) || (item && item.error === true)) {
      window.location.assign('//convela.de');
      return <></>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.logo} alt="CONVELA Logo" src={logo} />
          <img className={styles.appIcon} alt="App Icon" src={appIcon} onDoubleClick={() => {
            if (!isIOS) return;
            window.location.assign("cv-inventory:item?id=" + item.id)
          }} />
        </div>
        {item.images && item.images[0] && (
          <div className={styles.image} alt={item.description || item.type.name} style={{ backgroundImage: `url("${item.images[0].url}")`}} />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'baseline' }}>
          <h1>{item.type.name}, {item.color}</h1>
          <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 500, flexShrink: 0, marginLeft: 10 }}>ID: #{item.id}</span>
        </div>
        {item.description && (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, lineHeight: '24px' }}>
            <span style={{ fontSize: 11, textTransform: 'uppercase', fontWeight: 800, color: 'rgba(0, 0, 0, 0.5)' }}>Beschreibung:</span>
            <span>{item.description}</span>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '24px' }}>
          <span style={{ fontSize: 11, textTransform: 'uppercase', fontWeight: 800, color: 'rgba(0, 0, 0, 0.5)' }}>Zuletzt hier:</span>
          <span>{item.location}</span>
          <a className={styles.smallButton} href={`https://www.google.com/maps/search/${item.location}`} target="_blank" rel="noopener noreferrer">in Karten öffnen</a>
        </div>
        <div className={styles.buttons}>
          <div className={styles.button} onClick={() => this.setState({ isDamageReportModalOpen: true })}>
            Schaden melden
          </div>
        </div>
        <Modal
          title="Schaden melden"
          cancelText="Abbrechen"
          okText="Jetzt Schaden melden"
          visible={this.state.isDamageReportModalOpen}
          confirmLoading={this.state.isSubmittingDamageReport}
          onCancel={() => this.setState({ isDamageReportModalOpen: false })}
          onOk={() => {
            this.damageReportForm.current
              .validateFields()
              .then((values) => {
                const { data, imageIDs } = this.state;
                this.setState({ isSubmittingDamageReport: true });
                fetch(this.getAPIURL() + '/damage-reports/', {
                  method: 'post',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    itemID: item.id,
                    reporterName: data.reporterName,
                    reporterEmail: data.reporterEmail,
                    reporterPhone: data.reporterPhone,
                    type: data.type,
                    severity: data.severity,
                    location: data.location,
                    description: data.description,
                    imageIDs: imageIDs,
                  })
                }).then((response) => {
                  if (response.ok) {
                    this.setState({ isDamageReportModalOpen: false, isSubmittingDamageReport: false });
                    this.damageReportForm.current.resetFields();
                    alert("Vielen Dank, Ihre Schadensmeldung ist eingegangen. Sie haben per Email eine Kopie der Meldung erhalten.")
                  } else {
                    alert(response.json().error);
                  }
                }).catch((error) => {
                  this.setState({ isSubmittingDamageReport: false });
                  alert(error.message);
                });
              })
              .catch((info) => {});
          }}
        >
          <Form
            layout="vertical"
            onValuesChange={this.onFormChange}
            initialValues={{
              severity: "low",
              location: item.location
            }}
            scrollToFirstError
            preserve={false}
            ref={this.damageReportForm}
          >
            <div style={{ fontWeight: 500, marginBottom: 12, fontSize: 16 }}>Ihre Daten</div>
            <Form.Item label="Ihr Name" name="reporterName"  rules={[{ required: true, message: ' ' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Ihre Email-Adresse" name="reporterEmail" rules={[{ required: true, message: ' ' }, { type: 'email', message: 'Bitte eine gültige Email Adresse angeben.' }]} hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item label="Ihre Telefonnummer" name="reporterPhone" rules={[{ required: true, message: ' ' }]}>
              <Input />
            </Form.Item>
            <div style={{ fontWeight: 500, marginBottom: 12, fontSize: 16 }}>Der Schaden</div>
            <Form.Item label="Schwere des Schadens" name="severity"  rules={[{ required: true, message: ' ' }]}>
              <Radio.Group>
                <Radio.Button value="low">niedrig</Radio.Button>
                <Radio.Button value="mid">mittel</Radio.Button>
                <Radio.Button value="high">hoch</Radio.Button>
                <Radio.Button value="urgent">dringend</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Art des Schadens" name="type"  rules={[{ required: true, message: ' ' }]}>
              <Select>
                <Select.Option value="breakage">Bruch</Select.Option>
                <Select.Option value="scratch">Kratzer</Select.Option>
                <Select.Option value="paintDamage">Lackschaden</Select.Option>
                <Select.Option value="mechanicalDefect">mechanischer Defekt</Select.Option>
                <Select.Option value="impact">Schlag</Select.Option>
                <Select.Option value="scuff">Schramme</Select.Option>
                <Select.Option value="discoloration">Verfärbung</Select.Option>
                <Select.Option value="dirt">Verschmutzung</Select.Option>
                <Select.Option value="waterDamage">Wasserschaden</Select.Option>
                <Select.Option value="other">sonstige Schäden</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Ort der Entstehung des Schadens" name="location"  rules={[{ required: true, message: ' ' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Wie ist der Schaden entstanden?" name="description">
              <Input />
            </Form.Item>
            <Form.Item
              name="images"
              label="Bilder hochladen"
              valuePropName="images"
              getValueFromEvent={this.normFile}
              extra="unterstützte Formate: .jpg, .jpeg, .png"
            >
              <Upload name="images" listType="picture" multiple customRequest={({ onSuccess, onError, onProgress, file }) => {
                if (!['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
                  const error = new Error("Dateiformat wird nicht unterstützt.");
                  onError(error);
                  return;
                }
                const formData = new FormData();
                formData.append("files[]", file);
                let progress = 0;
                setInterval(() => {
                  if (progress >= 90) { clearInterval(); return };
                  progress = progress + 10;
                  onProgress(progress);
                }, 400)
                fetch(this.getAPIURL() + '/images', {
                  method: 'post',
                  body: formData,
                })
                  .then(response => response.json())
                  .then(response => {
                    const imageIDs = (this.state.imageIDs || []).concat(response.map(image => image.id));
                    this.setState({ imageIDs });
                    onSuccess();
                  })
                  .catch(error => {console.log(error); onError(error);})
              }}>
                <Button icon={<UploadOutlined />}>Bilder auswählen</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default ItemDetails;
